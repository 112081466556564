<template>
  <div>
    <div class="case-contaion">
      <div class="details-title">{{caseNews.title}}</div>
      <div class="input_video" v-if="playerOptions.sources[0].src">
        <van-skeleton :loading="loading" title avatar :animate="animate">
          <video-player height="180" class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
        </van-skeleton>
      </div>
      <div class="subtitle">
        {{caseNews.subTitle}}
      </div>
      <div class="details-clientele">客户：{{caseNews.customer}}</div>
      <div class="details-form">来自：{{caseNews.from}}</div>
      <div class="details-content" v-html="caseNews.content"></div>
    </div>
  </div>
</template>
<script>
import { getProductCaseInfo } from '@/api/service'
export default {
  name: 'CaseDetails',
  data() {
    return {
      caseId: undefined,
      caseNews: {},
      loading: true,
      animate: true,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "",
          src: ''//url地址
        }],
        // poster: "", //你的封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,//当前时间和持续时间的分隔符
          durationDisplay: true,//显示持续时间
          remainingTimeDisplay: false,//是否显示剩余时间功能
          fullscreenToggle: true  //全屏按钮
        }
      }
    }
  },

  created() {
    this.caseId = this.$route.query.caseId
    console.log('this.caseId结果===>', this.caseId)
    this.onload()
  },
  methods: {
    onload() {
      getProductCaseInfo({ caseId: this.caseId }).then((res) => {
        console.log('getProductCaseInfo结果===>',res)
        this.playerOptions.sources[0].src = res.data.video
        this.caseNews = res.data
        this.loading = false
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.case-contaion {
  padding: 0 10px;
}
.details-title {
  font-size: 18px;
  color: #333333;
  text-align: center;
  line-height: 46px;
  margin-top: 20px;
  letter-spacing: 1px;
  font-weight: 600;
}
.input_video {
  margin: 20px 0;
  width: 100%;
  height: 190px;
  overflow: hidden;
}
.subtitle {
  color: #666666;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}
.details-clientele,
.details-form {
  line-height: 28px;
  font-size: 14px;
  color: #333333;
}
.details-content {
  margin-top: 20px;
  font-size: 14px;
  color: #333333;
}
/deep/.vjs-custom-skin > .video-js .vjs-big-play-button {
  font-size: 16px !important;
}
</style>
